import React from "react";
import logEvent from "../../util/logEvent";

class ErrorPage extends React.Component {
  componentDidMount() {
    logEvent("error page");
  }
  render = () => (
    <div className="container" style={{ minHeight: "80vh" }}>
      <h5>Page Not Found</h5>
    </div>
  );
}

export default ErrorPage;
