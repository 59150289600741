const Palette = {
  black: "rgba(0, 0, 0, 1.0)",
  transparentBlack: "rgba(0, 0, 0, 0.7)",
  lime: "rgba(113, 181, 78, 1.0),",
  pink: "rgba(249, 46, 129, 1.0)",
  transparentPink: "rgba(249, 46, 129, 0.3)",
  transparent: "rgba(0, 0, 0, 0)",
  blue: "rgb(239, 30, 30)",
  darkblue: "rgba(141, 48, 217, 1.0)",
  transparentDarkblue: "rgba(141, 48, 217, 0.9)",
  white: "rgba(255, 255, 255, 1.0)",
  gray: "rgba(199,199,199,1.0)",
  transparentGray: "rgba(199,199,199,0.3)",
  booking: {
    states: {
      submitted: ["#fdd71c", "#f3cc0c"],
      confirmed: ["#5cde32", "#4ec328"],
      cancelled: ["#d4cbcb", "#b7afaf"],
      rejected: ["#d82828", "#b91616"],
      completed: ["#a741f6", "#c741f6"],
      expired: ["#d4cbcb", "#b7afaf"]
    }
  }
};

export default Palette;
