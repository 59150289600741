import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import styled from "styled-components";
import { Palette } from "../../theme";

const Container = styled.div`
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  ${props => props.halfWidth && "width: 50%"};
  align-items: center;
  ${props => props.marginBottom && "margin-bottom: 10px"};
  ${props => props.alignSelf && `align-self: ${props.alignSelf}`};
  ${props => props.marginTop && `margin-top: ${props.marginTop}px`};
`;

const DropdownBox = styled.button`
  border-width: 1;
  border-color: grey;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-horizontal: 5;
`;

const DropdownSelect = styled.button`
  border-bottom-width: 1;
  border-bottom-color: ${Palette.blue};
  padding-vertical: 5;
`;

export const weekDay = number => {
  switch (number) {
    case 0:
      return "Monday";
    case 1:
      return "Tuesday";
    case 2:
      return "Wednesday";
    case 3:
      return "Thursday";
    case 4:
      return "Friday";
    case 5:
      return "Saturday";
    case 6:
      return "Sunday";
    default:
      return number;
  }
};

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  openClose = () => {
    this.setState({
      open: !this.state.open
    });
  };

  select = item => {
    this.setState(
      {
        open: !this.state.open
      },
      () => this.props.update(item)
    );
  };

  render = () => {
    //console.log(this.props.name, ": ", this.props.val);
    return (
      <Container
        marginBottom={this.props.marginBottom}
        halfWidth={this.props.halfWidth}
        marginTop={this.props.marginTop}
        alignSelf={this.props.alignSelf}
      >
        <FormControl
          variant="outlined"
          className={this.props.className}
          style={{
            ...this.props.style,
            minWidth: this.props.width || 120,
            maxWidth: this.props.maxWidth,
            width: this.props.width
          }}
        >
          <InputLabel htmlFor={`outlined-${this.props.name}-simple`}>
            {this.props.name}
          </InputLabel>
          <Select
            value={this.props.val}
            multiple={this.props.multiple}
            onChange={item => this.select(item.target.value)}
            //labelWidth={this.props.width || 120}
            labelWidth={120}
            inputProps={{
              name: `${this.props.name}`,
              id: `outlined-${this.props.name}-simple`
            }}
          >
            {this.props.isObj
              ? Object.values(this.props.items).map((item, id) => {
                  let key = this.props.toShow
                    ? item[this.props.toShow]
                    : item.key;
                  return (
                    <MenuItem key={`key${id}`} value={item}>
                      {key}
                    </MenuItem>
                  );
                })
              : this.props.items.map((item, id) => (
                  <MenuItem key={`key${id}`} value={item === 0 ? "0" : item}>
                    {this.props.weekDays ? weekDay(item) : item}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Container>
    );
  };
}

export default Dropdown;
