const front = require("./front.jpg");
const balbino = require("./balbino.jpeg");
const logistika = require("./logistika.jpg");
const maxima = require("./maxima.jpg");
const coop = require("./coop.png");
const circlek = require("./circlek.png")
const vikos = require("./vikos.jpg")

export default {
  front,
  balbino,
  logistika,
  maxima,
  coop,
  circlek,
  vikos
};
