import React, { useEffect } from "react";
import logEvent from "../../util/logEvent";
import styled from "styled-components";
import translate from "../../util/translate";

const Padding = styled.div`
  padding: 20px;
  align-items: center;
  text-align: center;
`;

const Text = styled.h3`
  margin-bottom: 20px;
  color: #000;
  font-weight: 500;
`;

export const About = ({ language }) => {
  useEffect(() => {
    logEvent("About Page");
  }, []);
  return (
    <Padding>
      <Text>
        {translate("text1",language)}
      </Text>
      <Text>
        {translate("text2",language)}
      </Text>
      <Text>
        {translate("text3",language)}
      </Text>
      <Text>
        {translate("text4",language)}
      </Text>
    </Padding>
  );
};

export default About;
