import React from "react";
import styled from "styled-components";

const FooterText = styled.p`
	font-size: 20px;
	margin: 0;
`

export default () => {
  return (
    <footer className="page-footer bg-dark text-white mt-5 p-4 text-center" style={{
      position: "absolute",
      width: "100%",
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-evenly",
      alignContent: "center",
      alignItems: "center",
    }}>
       <FooterText>Copyright &copy; {new Date().getFullYear()} Tearo OÜ</FooterText>
    </footer>
  );
};
