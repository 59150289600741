import React, { useEffect } from 'react';
import styled from "styled-components";
import logEvent from "../../util/logEvent";

import translate from "../../util/translate";

const Container = styled.div`
  margin: 40px;
  display: flex;
  min-height: 50vh;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CompContainer = styled.div`
    display: inherit;
    flex-direction: column;
    margin-bottom: 20px;
`;

const Title = styled.h3`
  margin-bottom: ${prop => prop.noBottom ? 0 : '20px'};
  margin-top: 20px;
  font-weight: 500;
  color: #000;
`;
const Text = styled.p`
  margin: 10px;
  font-size: 25px;
  color: #000;
`;

const TextContainer = styled.div`
  margin-bottom: 40px;
`;

const CallUs = ({ number,language }) => (
    <CompContainer>
      <Text> {translate('telephone',language)}: {number} </Text>
      <hr />
      <Text>{translate('email',language)}: tearo.ou@gmail.com</Text>
      <hr />
      <Text>{translate('address',language)}: Betooni 11B, 11415 Tallinn</Text>
    </CompContainer>
  );

export default ({ language }) => {
    useEffect(() => {
      logEvent('Contacts Page')
    },[])
    return (
        <Container>
        <CallUs number="+372 53906136" {...{language}} />
      </Container>
    )
}
