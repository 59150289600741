const translations = {
  Eesti: {
    address: 'Aadress',
    partners: "Partnerid",
    about: "Firmast",
    contact: "Kontakt",
    callus: "Helistage",
    telephone: "Tel.",
    email: "Email",
    text1: "Tearo OÜ on asutatud 2001.aastal ja põhineb 100% Eesti kapitali.",
    text2: `Meie põhitegevuseks on veoteenused üle Eesti. Tearo OÜ omandab üle 13 
        veoautod ja kõik meie autod varustatud luuktõstukitega ja omavad
        külmutusseadmeid. Kõik meie sõidukid vastavad kehtivatele toiduveo
        normidele.`,
    text3: ` Ettevõttes töötab oma lukksepatöökoda, mis võimaldab operatiivselt
        kõrvaldada rikkiminekuid ning erineva raskusastmega remonti, tagades
        ladusa töö ja välistades klientidele ebamugavuse tekitamise.`,
    text4: `Tänu meie meeskonnale ja masinpargile suudame pakkuda oma praegustele ja
        tulevastele klientidele parimat teenust.`
  },
  English: {
    address: 'Address',
    partners: "Partners",
    about: "About Us",
    contact: "Contacts",
    callus: "Call us",
    telephone: "Tel.",
    email: "Email",
    text1: "Tearo OÜ was established in 2001 on 100% estonian capital",
    text2:
      "Our main service is transportation across Estonia. We own 13 trucks and each of them has lifts for hatchets and refrigerators.Furthermore, all of the vehicles comply with applicable food regulations. ",
    text3:
      "The company has its own locksmith workshop, which allows you to quickly troubleshoot malfunctions and repairs of varying severity, ensure smooth operation and eliminate inconvenience to customers.",
    text4:
      "Thanks to our team, we can offer the best service to our current and future customers."
  },
  Русский: {
    address: 'Адрес',
    partners: "Партнёры",
    about: "О нас",
    contact: "Контакты",
    callus: "Позвоните нам",
    telephone: "Тел.",
    email: "Емайл",
    text1:
      "Фирма Tearo OÜ была основана в 2001 году и базируется на 100% эстонском капитале.",
    text2:
      "Наша основная деятельность - транспортные услуги по всей Эстонии. Tearo OÜ имеет в своем автопарке  более 13 грузовых автомобилей.  Все наши грузовые автомобили оснащены лифтами и холодильным оборудованием. Также Все наши автомобили соответствуют действующим правилам перевозки пищевых продуктов.",
    text3:
      "Компания имеет собственную слесарную мастерскую, которая позволяет оперативно устранять неисправности и ремонты различной степени тяжести, обеспечивать бесперебойную работу и устранять неудобства для клиентов.",
    text4:
      "Благодаря нашей команде , мы можем предложить лучший сервис для наших нынешних и будущих клиентов."
  }
};

export default (name, language) => {
  return Object.entries(translations).find(a => a[0] === language)[1][name];
};
