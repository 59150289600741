import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logEvent from "../../util/logEvent";
import images from "../../images";
import styled from "styled-components";

import About from "./About";
import Contacts from "./Contacts";
import LanguageContext from "../../util/context";
import translate from "../../util/translate";

const partners = [
  {
    name: "balbino",
    image: images.balbino
  },
  {
    name: "logistika",
    image: images.logistika
  },
  {
    name: "coop",
    image: images.coop
  },
  {
    name: "maxima",
    image: images.maxima
  },

  {
    name: "circlek",
    image: images.circlek
  },
  {
    name: "vikos trading oü",
    image: images.vikos
  }
];

const Divider = styled.hr`
  width: 90%;
`;

const HeaderSpan = styled.span`
  display: inline-block;
  background-color: white;
  position: relative;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  padding: 0 8.5%;
  letter-spacing: 3.6px;
`;

const Section = ({ children, bg, header, noMarginBottom }) => (
  <SectionContainer bg={bg} width="100%" {...{noMarginBottom}}>
    <SectionHeader>
      <HeaderSpan> {header}</HeaderSpan>
    </SectionHeader>
    {children}
  </SectionContainer>
);

const SectionContainer = styled.div`
  ${props => (props.width ? `width: ${props.width}` : "100%")};
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  ${prop => !prop.noMarginBottom && "margin-bottom: 60px"};
  ${props =>
    props.bg &&
    `background: url(${props.bg}) no-repeat center; background-size: 100%;`}
`;

const SectionHeader = styled.h2`
  margin-bottom: ${prop => (prop.noMargin ? 0 : "40px")};
  margin-top: ${prop => (prop.noMargin ? 0 : "40px")};
  text-align: center;
  position: relative;
  width: 100%;
  :before {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0;
    height: 3px;
    background-color: black;
  }
`;

const SectionDesc = styled.p`
  font-size: 20px;
`;

const Row = styled.div`
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BG = styled.div`
  position: relative;
  width: 100%;
  height: 30vh;
  &:after {
    content: "";
    background: url(${images.front}) no-repeat center;
    opacity: 0.9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

const Header = styled.h3`
  font-size: 40px;
  color: #000;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 30px;
    margin-top 30px;
    margin-bottom: 20px
  }
`;

const Padding = styled.div`
 padding: 20px 0;
`;

const Partners = ({ partners = [] }) => {
  return (
    <Row>
      {partners.map((p, i) => {
        return (
          <Padding key={i}>
            {" "}
            {p.image && <img src={p.image} style={{ width: 200 }} />}
            {!p.image && <Header>{p.name}</Header>}
          </Padding>
        );
      })}
    </Row>
  );
};

const Landing = () => {
  useEffect(() => {
    logEvent("landing page");
  }, []);
  return (
    <LanguageContext.Consumer>
      {([language]) => {
        return (
          <div className="landing">
            <div className="landing-inner text-light">
              <BG></BG>
              <div className="container">
                <Section bg={null} header={translate("about", language)}>
                  <About {...{ language }} />
                </Section>
                <Section bg={null} header={translate("partners", language)}>
                  <Partners {...{ partners }} />
                </Section>
                <Section bg={null} header={translate("contact", language)} noMarginBottom>
                  <Contacts {...{ language }} />
                </Section>
              </div>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Landing;
