import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import Contacts from "./components/layout/Contacts";
import About from "./components/layout/About";

import Spinner from "./components/shared/Spinner";
import ErrorPage from "./components/layout/ErrorPage";

import { createHashHistory as createHistory } from "history";
import LanguageContext from "./util/context";

const history = createHistory();

const App = () => {
  const language = useState("Eesti");
  return (
    <Router history={history}>
      <LanguageContext.Provider value={language}>
        <div className="App">
          <Navbar />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route component={ErrorPage} />
          </Switch>
          <Footer />
        </div>
      </LanguageContext.Provider>
    </Router>
  );
};

export default App;
