import React, { useContext } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import Dropdown from "../../components/shared/Dropdown";
import LanguageContext from "../../util/context";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";

const CompanyName = styled.h2`
  color: #000;
  font-weight: 600;
`;

const NavBarText = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
`;

const StyledDropdown = styled(Dropdown)`
  @media (max-width: 768px) {
  }
`;

const Navbar = () => {
  const [themeLanguage, setThemeLanguage] = useContext(LanguageContext);
  return (
    <nav
      className="navbar navbar-expand-sm navbar-light bg-light mb-4"
      style={{ flexDirection: "column" }}
    >
      <div
        className="container"
        style={{
          minHeight: "10vh",
          maxWidth: "initial",
          display: "flex"
        }}
      >
        <Link className="navbar-brand main" to="/" >
          <CompanyName>TEARO OÜ</CompanyName>
        </Link>
        {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#mobile-nav-merchant"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
        <Dropdown
          items={["Eesti", "English", "Русский"]}
          val={themeLanguage}
          className="dropdowny"
          name="Language"
          style={{ position: "absolute", top: 20, right: "5vw" }}
          update={val => {
            setThemeLanguage(val);
          }}
        />

        {/* <div className="collapse navbar-collapse" id="mobile-nav-merchant">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  <NavBarText>About</NavBarText>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/about">
                  <NavBarText> About </NavBarText>
                </Link>
              </li> */}
        {/* <li className="nav-item">
                <Link className="nav-link" to="/contacts">
                  <NavBarText> Contact Us </NavBarText>
                </Link>
              </li>
            </ul>
          </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
